@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Dancing+Script:wght@700&family=Kaushan+Script&family=Poppins:wght@100;300;400;500;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #111111;
    width: 100%;
    overflow-x: hidden;
    color: #fff;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    color: #fff;
}

/*-------------- navbar css ------------------*/

.main_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15%;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 35px;
    width: 35px;
}

.menu-link {
    width: 40%;
}

.menu-link ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

ul li {
    list-style-type: none;
}

ul li .navbar_link {
    text-decoration: none;
    font-size: 14px;
}

/* menu */

.hamburgur-menu {
    border: 1px solid #f9f9f9;
    border-radius: 10px;
    display: none;
    position: relative;
}

/*-------------- home css -------------*/

.home{
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 10vh;
}

.home_img{
    display: flex;
    flex-direction: column;
}

.home_img img {
    height: 250px;
    width: 250px;
    object-fit: contain;
    margin: 0 auto;
}

.home_img p{
    text-align: center;
}


/* -------------  about css -------------- */

.about {
    margin-top: 2rem;
}

.about_headings {
    display: flex;
    justify-content: center;
}

.about_heading {
    color: #fff;
    display: inline-block;
    font-size: 20px;
    padding: 5px 3rem;
    clip-path: polygon(100% 0%, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
    background-color: gray;
    margin-bottom: 2rem;
}

.about_detils {
    padding: 20px 15%;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    width: 100%;
}

.about_detils_content h3 {
    border-bottom: 1px solid black;
    margin-bottom: 3px;
}

.about_detils_content p {
    font-size: 12px;
}

/* -------------  service css -------------- */

.service {
    margin-top: 2rem;
}

.service_headings {
    display: flex;
    justify-content: center;
}

.service_heading {
    color: #fff;
    display: inline-block;
    font-size: 20px;
    padding: 5px 3rem;
    clip-path: polygon(100% 0%, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
    background-color: gray;
    margin-bottom: 2rem;
}

.service_cards {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.service_card {
    width: 60%;
    border-radius: .5rem;
    padding: 40px 32px;
    outline-offset: -1rem;
    outline: .1rem solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: inset 1px 1px 10px #333;
    -moz-box-shadow: inset 1px 1px 10px #333;
    box-shadow: inset 1px 1px 10px #333;
    transition: .2s linear;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.service_card h1 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}

.service_card_detils {
    display: flex;
    gap: 10px;
}

.service_card_detils img {
    width: 50%;
    height: 250px;
}

.service_card_detils p {
    width: 50%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
}

/* -------------  contact page --------- */

.contact {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.contact_headings {
    display: flex;
    justify-content: center;
}

.contact_heading {
    color: #fff;
    display: inline-block;
    font-size: 20px;
    padding: 5px 3rem;
    clip-path: polygon(100% 0%, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
    background-color: gray;
    margin-bottom: 2rem;
}

.row {
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 0 20px 20px 20px;
}

.row1 {
    display: flex;
    gap: 20px;
    flex: 2;
}

.row2 {
    flex: 1;
}

.contact_card {
    height: 150px;
    flex: 1;
    text-align: center;
    padding: 25px 10px;
    border-radius: 5px;
    font-size: 14px;
    background: transparent;
    transition: transform 0.5s, background 0.5s;
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-box-shadow: 1px 1px 10px gray;
    -moz-box-shadow: 1px 1px 10px gray;
    box-shadow: 1px 1px 10px gray;
}

.contact_card span {
    color: black;
}

.mail_link{
    color: black;
}

.contact_card div {
    margin-bottom: 10px;
}

.contact_card h1 {
    font-weight: 600;
    margin-bottom: 8px;
}

.contact_card:hover {
    transform: scale(1.05);
}


/* --------  footer  --------- */

.footer_icon {
    height: 15vh;
    width: 100%;
    background: black;
}

.footer_social_icons {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.footer_social_icon {
    -webkit-box-shadow: inset 1px 1px 10px #333;
    -moz-box-shadow: inset 1px 1px 10px #333;
    box-shadow: inset 1px 1px 10px #333;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* ------------ media queariy --------*/


@media (max-width:1070px) {
    .about_detils {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media (max-width:1050px) {
    .service_card_detils p {
        line-height: 20px;
    }
}

@media (max-width:900px) {
    .home_img {
        background-size: cover;
    }

    .service_card {
        min-width: 500px;
    }

    .service_card_detils {
        flex-direction: column;
    }

    .service_card_reverse {
        flex-direction: column-reverse
    }

    .service_card_detils img {
        width: 100%;
    }

    .service_card_detils p {
        width: 100%;
    }
}

@media (max-width:800px) {
    .contact_card {
        height: 210px;
    }
}

@media (max-width:786px) {
    .menu-link {
        display: none;
    }

    .mobile-menu-link {
        display: block;
        position: absolute;
        z-index: 99;
        top: 60px;
        right: 0;
    }

    .mobile-menu-link {
        background-color: #111111;
        height: 15rem;
        width: 100%;
        display: grid;
        padding-left: 3rem;
        transition: all 2s linear;
        transform-origin: top;
    }

    .mobile-menu-link ul {
        height: 15rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
    }

    .main_div{
        padding: 5px 15px;
    }

    .hamburgur-menu {
        display: block;
        font-size: 1.5rem;
    }

    .hamburgur-menu a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .about_detils {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .about_detils_content p {
        font-size: 10px;
    }

    .about_detils_content h3 {
        font-size: 16px;
    }
}

@media (max-width:700px) {
    .row {
        flex-direction: column;
    }

    .row2 {
        margin: 0 auto;
    }

    .home_img p{
        margin: 0 20px;
    }
}

@media (max-width:500px) {
    .service_card {
        min-width: 400px;
    }

    .service_card_detils img {
        width: 100%;
    }

    .service_card_detils p {
        width: 100%;
        line-height: 18px;
        letter-spacing: 0;
        font-size: .8rem;
    }

    .row1 {
        flex-direction: column;
    }

    .row2 {
        margin: 0;
    }
}

@media (max-width:400px) {
    .service_card {
        min-width: 350px;
    }

    .service_card h1 {
        font-size: 20px;
    }

    .service_card_detils p {
        font-size: .7rem;
    }
}

@media (max-width:350px) {
    .about_heading {
        font-size: 1.8rem;
        padding: .4rem 2.5rem;
    }

    .service_heading {
        font-size: 1.8rem;
        padding: .4rem 2.5rem;
    }

    .contact_heading {
        font-size: 1.8rem;
        padding: .4rem 2.5rem;
    }

    .hamburgur-menu {
        font-size: 1.5rem;
    }

    .service_card {
        min-width: 300px;
    }

    .service_card h1 {
        font-size: 18px;
    }
}

@media (max-width:330px) {
    .about_detils {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .about_detils_content h3 {
        font-size: 14px;
    }
}

@media (max-width:300px) {
    .service_card {
        min-width: 250px;
    }

    .service_card h1 {
        font-size: 14px;
    }

    .service_card_detils p {
        line-height: 14px;
        font-size: .6rem;
    }
}